import Routes from './routes';
//import './App.css';


function App() {
  return (
    <div className="App"  >
          <Routes />
    </div>
  );
}

export default App;
