import { Outlet } from 'react-router-dom';
import React, { useEffect } from 'react';

const MainLayout = () => {
  useEffect(() => {
    // Code to run on component mount or update
  }, []);

  return (
    <div>
      {/* Your layout content goes here */}
      <Outlet />
    </div>
  );
};

export default MainLayout;