import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
//import AuthenticationRoutes from './AuthenticationRoutes';
import HealthCheckRoute from './healthCheck';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([ MainRoutes, HealthCheckRoute]);  //,AuthenticationRoutes]);
}
