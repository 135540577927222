import React from 'react';
// create helthCheck component
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';

const HealthCheck = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Typography sx={{ m: 5 }} variant="h3">
        Health Check Success
      </Typography>
      <Button sx={{ m: 5 }} onClick={() => navigate('/')} variant="contained">
        Go Home
      </Button>
    </Box>
  );
};

export const HealthCheckRoute = {
  path: '/health',
  element: <HealthCheck />
};

export default HealthCheckRoute;
